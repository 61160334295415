import { StyleSheet } from "react-native";

const screenStyles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    top: 5,
    left: 5,
    flex: 1,
  },
  mainContent: {
    position: "relative",
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  },
  blogContent: {
    position: "relative",
    //flex: 1,
    backgroundColor: "transparent",
    //alignItems: "center",
    //justifyContent: "center",
  },
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
});

export default screenStyles;
