import React from "react";
import { Text, View } from "react-native";
import MenuIcon from "../Components/MenuIcon";
import screenStyles from "./ScreenStyles";

export const About = ({ toggleSideMenu, menuOpen }) => {
  return (
    <View style={screenStyles.container}>
      <View style={menuOpen ? screenStyles.hidden : screenStyles.visible}>
        <MenuIcon handler={toggleSideMenu}></MenuIcon>
      </View>
      <View style={screenStyles.mainContent}>
        <Text>About</Text>
      </View>
    </View>
  );
};
