import url from "url";
import { useHistory } from "react-router";
import React, { useEffect } from "react";
import { Linking } from "react-native";

const InnerLinker = () => {
  const history = useHistory();
  const handleInitialLoad = async () => {
    const urlObj = url.parse(await Linking.getInitialURL());
    history.push(urlObj.path);
  };

  useEffect(() => {
    handleInitialLoad();
  }, []);
  return <div></div>;
};

export default InnerLinker;
