import React from "react";
import { StyleSheet, Text, View, Linking, Button, Image } from "react-native";
import MenuIcon from "../Components/MenuIcon";
import screenStyles from "./ScreenStyles";

const styles = StyleSheet.create({
  emailTitle: {
    textAlign: "center",
  },
  box: {
    alignSelf: "stretch",
    position: "relative",
    margin: 5,
  },
  debugbox: {
    borderWidth: 5,
    borderStyle: "solid",
  },
});

export const Contact = ({ toggleSideMenu, menuOpen }) => {
  return (
    <View
      style={[screenStyles.container, styles.box]}
      nativeID="outercontainer"
    >
      <View
        style={menuOpen ? screenStyles.hidden : screenStyles.visible}
        nativeID="menuicon"
      >
        <MenuIcon handler={toggleSideMenu}></MenuIcon>
      </View>
      <View
        style={[screenStyles.mainContent, styles.box]}
        nativeID="mainContent"
      >
        <View
          style={[{ flex: 1, marginBottom: 35 }, styles.box]}
          nativeID="enquiries"
        >
          <Text style={[styles.emailTitle, { fontSize: 32 }]}>Contact Us</Text>
          <View
            nativeID="bar1"
            style={[
              styles.box,
              {
                borderBottomColor: "black",
                borderBottomWidth: 1,
              },
            ]}
          />
          <Text style={[styles.emailTitle, { fontSize: 24 }]}>
            General Enquiries
          </Text>
          <Button
            onPress={() => Linking.openURL("mailto:info@bicm-consulting.co.uk")}
            title="info@bicm-consulting.co.uk"
          />
        </View>
        <View
          nativeID="bar2"
          style={[
            styles.box,
            {
              marginTop: 10,
              borderBottomColor: "black",
              borderBottomWidth: 1,
            },
          ]}
        />
        <Text style={{ fontSize: 24 }}>Directors</Text>
        <View
          style={[{ flex: 1, flexDirection: "row" }, styles.box]}
          nativeID="directors"
        >
          <View style={{ flex: 1, margin: 10 }}>
            <Text style={styles.emailTitle}>Mike Wardle</Text>
            <Button
              onPress={() =>
                Linking.openURL("mailto:mike@bicm-consulting.co.uk")
              }
              title="mike@bicm-consulting.co.uk"
            />
          </View>
          <View style={{ flex: 1, margin: 10 }}>
            <Text style={styles.emailTitle}>Christine Wardle</Text>
            <Button
              onPress={() =>
                Linking.openURL("mailto:christine@bicm-consulting.co.uk")
              }
              title="christine@bicm-consulting.co.uk"
            />
          </View>
        </View>
      </View>
    </View>
  );
};
