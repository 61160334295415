import React, { useEffect, useState } from "react";
import { View } from "react-native";
import MenuIcon from "../Components/MenuIcon";
import screenStyles from "./ScreenStyles";
import useFetch from "../Hooks/useFetch";
import Blog from "../Types/BlogTypes";
import BlogLink from "../Components/BlogLink";
import settings from "../Config/EnvironmentSettings";

function compareBy(property: string) {
  return function compare(
    a: { [x: string]: number },
    b: { [x: string]: number }
  ) {
    if (a[property] < b[property]) {
      return 1;
    }
    if (a[property] > b[property]) {
      return -1;
    }
    return 0;
  };
}

export const Blogs = ({ toggleSideMenu, menuOpen }) => {
  const response = useFetch(settings.blogListUri);
  const [articles, setArticles] = useState<Array<typeof BlogLink>>([]);
  useEffect(() => {
    console.log(response);
    if (response.status.toUpperCase() === "FETCHED") {
      setArticles(
        response.data.blogs.Items.sort(
          compareBy("Published")
        ).map((blog: Blog) => <BlogLink key={blog.BlogId} blog={blog} />)
      );
    }
  }, [response]);
  return (
    <View style={screenStyles.container}>
      <View style={menuOpen ? screenStyles.hidden : screenStyles.visible}>
        <MenuIcon handler={toggleSideMenu}></MenuIcon>
      </View>
      <View
        style={[
          screenStyles.mainContent,
          { flex: 1, flexDirection: "row", flexWrap: "wrap" },
        ]}
      >
        {articles}
      </View>
    </View>
  );
};
