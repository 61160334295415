import React, { useState } from "react";
import Home from "./Screens/Home";
import SideMenu from "react-native-side-menu";
import Menu from "./Components/Menu";
import { NativeRouter, Route } from "react-router-native";
import { View } from "react-native";
import { About } from "./Screens/About";
import { Contact } from "./Screens/Contact";
import { Services } from "./Screens/Services";
import { Products } from "./Screens/Products";
import { Blogs } from "./Screens/Blogs";
import { RenderedBlog } from "./Screens/RenderedBlog";
import { Redirect } from "react-router";
import InnerLinker from "./Components/InnerLinker";

export default function App() {
  const [menuOpen, setOpen] = useState(false);
  return (
    <NativeRouter>
      <SideMenu
        onChange={(isOpen) => setOpen(isOpen)}
        menu={
          <Menu
            toggleSideMenu={() => {
              console.log("toggle " + menuOpen);
              setOpen(!menuOpen);
            }}
            menuOpen={menuOpen}
          />
        }
        isOpen={menuOpen}
      >
        <View>
          <InnerLinker />
          <Route
            exact
            path="/"
            render={() => (
              <Home
                menuOpen={menuOpen}
                toggleSideMenu={() => setOpen(!menuOpen)}
              />
            )}
          />
          <Route
            path="/about"
            render={() => (
              <About
                menuOpen={menuOpen}
                toggleSideMenu={() => setOpen(!menuOpen)}
              />
            )}
          />
          <Route
            path="/products"
            render={() => (
              <Products
                menuOpen={menuOpen}
                toggleSideMenu={() => setOpen(!menuOpen)}
              />
            )}
          />
          <Route
            path="/services"
            render={() => (
              <Services
                menuOpen={menuOpen}
                toggleSideMenu={() => setOpen(!menuOpen)}
              />
            )}
          />
          <Route
            path="/contact"
            render={() => (
              <Contact
                menuOpen={menuOpen}
                toggleSideMenu={() => setOpen(!menuOpen)}
              />
            )}
          />
          <Route
            exact
            path="/articles"
            render={() => (
              <Blogs
                menuOpen={menuOpen}
                toggleSideMenu={() => setOpen(!menuOpen)}
              />
            )}
          />
          <Route
            path="/blog/:id"
            render={() => (
              <RenderedBlog
                menuOpen={menuOpen}
                toggleSideMenu={() => setOpen(!menuOpen)}
              />
            )}
          />
          <Redirect to="/" />
        </View>
      </SideMenu>
    </NativeRouter>
  );
}
