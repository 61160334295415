import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View } from "react-native";
import Icon from "react-native-vector-icons/Octicons";
import { Link } from "react-router-native";
import CollapsibleList from "react-native-collapsible-list";
import useFetch from "../Hooks/useFetch";
import settings from "../Config/EnvironmentSettings";

const styles = StyleSheet.create({
  fullMenu: {
    flex: 1,
    backgroundColor: "#ededed",
    paddingTop: 50,
  },
  closeIcon: {
    right: 20,
    top: 5,
    position: "absolute", // add if dont work with above
  },
  mainContent: {
    alignItems: "center",
    justifyContent: "center",
  },
  visible: { opacity: 1 },
  hidden: { opacity: 0, width: 0 },
  menuTitleTouch: {
    backgroundColor: "black",
    width: "100%",
    padding: 5,
  },
  menuTitle: {
    textAlign: "center",
    backgroundColor: "black",
    color: "white",
    width: "100%",
    fontSize: 16,
    fontWeight: "bold",
    padding: 5,
  },
  menuItemTouch: { borderBottomWidth: 2, borderColor: "#444", width: "100%" },
  menuItem: {
    textAlign: "center",
    borderBottomWidth: 2,
    borderColor: "#444",
    fontSize: 14,
    width: "100%",
  },
  menuSectionHeader: {
    textAlign: "center",
    backgroundColor: "#111",
    color: "white",
    width: "100%",
    fontSize: 14,
    fontWeight: "bold",
  },
  wrapperCollapsibleList: {
    overflow: "hidden",
    width: "100%",
    borderRadius: 5,
  },
});

const CloseMenuIcon = ({ handler }) => (
  <Icon name="x" size={30} color="#000" onPress={() => handler()} />
);

function sortBy(property: string) {
  return function compare(a: any, b: any) {
    if (a[property] < b[property]) {
      return 1;
    }
    if (a[property] > b[property]) {
      return -1;
    }
    return 0;
  };
}

const Menu = ({ toggleSideMenu, menuOpen }) => {
  const response = useFetch(settings.blogListUri);
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    console.log(response);
    if (response.status.toUpperCase() === "FETCHED") {
      setArticles(
        response.data.blogs.Items.sort(sortBy("Published")).map((blog) => (
          <Link
            key={blog.BlogId}
            onPress={() => {
              console.log("on press" + toggleSideMenu);
              toggleSideMenu();
            }}
            style={styles.menuItemTouch}
            to={`/blog/${blog.BlogId}`}
          >
            <Text style={styles.menuItem}>{blog.Title}</Text>
          </Link>
        ))
      );
    }
  }, [response]);
  return (
    <View style={[styles.fullMenu, menuOpen ? styles.visible : styles.hidden]}>
      <View
        style={[styles.closeIcon, menuOpen ? styles.visible : styles.hidden]}
      >
        <CloseMenuIcon handler={toggleSideMenu} />
      </View>
      <View style={styles.mainContent}>
        <Link
          onPress={() => toggleSideMenu()}
          to="/"
          style={styles.menuTitleTouch}
        >
          <Text style={styles.menuTitle}>BICM Consulting Limited</Text>
        </Link>
        <CollapsibleList
          numberOfVisibleItems={0}
          wrapperStyle={styles.wrapperCollapsibleList}
          buttonContent={
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.menuSectionHeader}>Technical Articles</Text>
              <Icon
                name="chevron-down"
                size={24}
                color="#FFF"
                style={{ backgroundColor: "#111", paddingRight: 5 }}
              />
            </View>
          }
        >
          <Link
            onPress={() => toggleSideMenu()}
            style={styles.menuItemTouch}
            to="/articles"
          >
            <Text style={styles.menuItem}>Full List</Text>
          </Link>
          {articles}
        </CollapsibleList>
        <Link
          onPress={() => toggleSideMenu()}
          style={styles.menuItemTouch}
          to="/services"
        >
          <Text style={styles.menuItem}>Services</Text>
        </Link>
        <Link
          onPress={() => toggleSideMenu()}
          style={styles.menuItemTouch}
          to="/products"
        >
          <Text style={styles.menuItem}>Available Products</Text>
        </Link>
        <Link
          style={styles.menuItemTouch}
          onPress={() => toggleSideMenu()}
          to="/about"
        >
          <Text style={styles.menuItem}>About</Text>
        </Link>
        <Link
          onPress={() => toggleSideMenu()}
          style={styles.menuItemTouch}
          to="/contact"
        >
          <Text style={styles.menuItem}>Contact Us</Text>
        </Link>
      </View>
    </View>
  );
};

export default Menu;
