const settings = {
  // blogListUri: "http://localhost:3000/api/blogs.json",
  // blogIconsCDN: "http://localhost:3000/blogIcons",
  // blogLogosCDN: "http://localhost:3000/blogLogos",
  // blogDetailsCDN: "http://localhost:3000/blogDetails",
  blogListUri:
    "https://373gsqczj1.execute-api.us-east-2.amazonaws.com/prod/blogs",
  blogIconsCDN: "https://d1rdjzol0gqp1j.cloudfront.net",
  blogLogosCDN: "https://d3dss7a44acnkh.cloudfront.net",
  blogDetailsCDN: "https://d19idijf5ldbny.cloudfront.net",
};

export default settings;
