import React, { useEffect } from "react";

const Ad = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);
  return (
    <div className="ad">
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3760160700887752"
        // data-ad-slot="xxxxxxxxxx"
        data-ad-format="auto"
      />
    </div>
  );
};

export default Ad;
