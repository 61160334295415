import React from "react";
import { Text, View, Image } from "react-native";
import { Link } from "react-router-native";
import settings from "../Config/EnvironmentSettings";

const BlogLink = ({ blog }) => {
  return (
    <Link key={blog.BlogId} to={`/blog/${blog.BlogId}`}>
      <View
        style={{
          flexDirection: "row",
          alignContent: "center",
          flex: 1,
          justifyContent: "center",
          borderWidth: 2,
          borderColor: "black",
          borderRadius: 10,
          padding: 4,
          margin: 5,
        }}
      >
        <Text style={{ flexWrap: "wrap", maxWidth: 200 }}>{blog.Title}</Text>
        <Image
          style={{ width: 25, height: 25 }}
          source={{
            uri: `${settings.blogIconsCDN}${blog.Icon}`,
          }}
        />
      </View>
    </Link>
  );
};

export default BlogLink;
