import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  ScrollView,
  Dimensions,
} from "react-native";
import MenuIcon from "../Components/MenuIcon";
import screenStyles from "./ScreenStyles";
import { useParams } from "react-router-native";
import useFetch from "../Hooks/useFetch";
import { BlogDetails, BlogItem } from "../Types/BlogTypes";
import settings from "../Config/EnvironmentSettings";
import Ad from "../Components/Ad";
import YoutubePlayer from "react-native-youtube-iframe";
import { NativeWebView } from "../Components/NativeWebView";

const ScreenHeight = Dimensions.get("window").height;

console.log("screen: " + ScreenHeight);

const codeIndent = (indent?: number) => {
  return StyleSheet.create({
    style: {
      marginLeft: indent * 20,
    },
  });
};

const imageStyle = (width: number, height: number) => {
  return StyleSheet.create({
    style: {
      minWidth: width,
      minHeight: height,
      paddingTop: height,
      paddingRight: width,
    },
  });
};

const styles = StyleSheet.create({
  title: {
    fontWeight: "bold",
    fontSize: 32,
  },
  bodyParagraph: { marginTop: 3, marginBottom: 3 },
  body: {
    fontSize: 12,
  },
  heading: { fontSize: 18, fontWeight: "bold" },
  image: {
    minHeight: 10,
    minWidth: 10,
  },
  outerView: { height: ScreenHeight },
  codeSection: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "black",
    backgroundColor: "#fffdd0",
    padding: 5,
  },
  codeLine: {
    fontFamily: "consolas",
  },
});

function sortBy(property: string) {
  return function compare(a: BlogItem, b: BlogItem) {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  };
}

function buildContents(
  blog: BlogDetails,
  setter: React.Dispatch<React.SetStateAction<any[]>>,
  id: number
) {
  const elements = blog.contents.sort(sortBy("index")).map((item, idx) => {
    if (item.type === "body") {
      return (
        <View key={`ele${idx}`} style={styles.bodyParagraph}>
          <Text style={styles.body}>{item.value}</Text>
        </View>
      );
    } else if (item.type === "heading") {
      return (
        <View key={`ele${idx}`}>
          <Text style={styles.heading}>{item.value}</Text>
        </View>
      );
    } else if (item.type === "image") {
      return (
        <View key={`ele${idx}`}>
          <Image
            source={{
              uri: `${settings.blogDetailsCDN}/${id}/${item.value}`,
            }}
            style={[
              imageStyle(item.width, item.height).style,
              { alignSelf: "center" },
            ]}
          ></Image>
        </View>
      );
    } else if (item.type === "code") {
      const lines = item.lines.map((line, idx2) => {
        return (
          <Text
            key={`ele${idx}-${idx2}`}
            style={[styles.codeLine, codeIndent(line.indent).style]}
          >
            {line.value}
          </Text>
        );
      });
      return (
        <View
          key={`ele${idx}`}
          style={[styles.codeSection, styles.bodyParagraph]}
        >
          {lines}
        </View>
      );
    } else if (item.type === "numberedList") {
      const lines = item.lines.map((line, idx2) => {
        return (
          <Text
            key={`ele${idx}-${idx2}`}
            style={[codeIndent(2).style, styles.body]}
          >
            {idx2 + 1}. {line}
          </Text>
        );
      });
      return (
        <View key={`ele${idx}`} style={styles.bodyParagraph}>
          {lines}
        </View>
      );
    } else if (item.type === "advert") {
      return <Ad key={`ele${idx}`}></Ad>;
    } else if (item.type === "webview") {
      console.log("webview");
      return (
        <View key={`ele${idx}`} nativeID={`youtube$idx`}>
          <NativeWebView target={item.value} />
        </View>
      );
    }
  });
  setter(elements);
}

export const RenderedBlog = ({ toggleSideMenu, menuOpen }) => {
  const { id } = useParams();
  const response = useFetch(`${settings.blogDetailsCDN}/${id}/content.json`);
  const [blog, setBlog] = useState<BlogDetails | undefined>({
    title: "",
    published: 0,
    contents: [],
  });
  const [contents, setContent] = useState([]);
  useEffect(() => {
    console.log(response);
    if (response.status.toUpperCase() === "FETCHED") {
      setBlog(response.data);
      buildContents(response.data, setContent, id);
    }
  }, [response]);
  return (
    <View style={styles.outerView} nativeID="blogscreen">
      <View
        style={menuOpen ? screenStyles.hidden : screenStyles.visible}
        nativeID="menuIconView"
      >
        <MenuIcon handler={toggleSideMenu}></MenuIcon>
      </View>
      <ScrollView
        style={[styles.outerView, { paddingLeft: 10, paddingRight: 10 }]}
        nativeID="scrollView"
      >
        <Text style={styles.title}>{blog.title}</Text>
        {contents}
      </ScrollView>
    </View>
  );
};
